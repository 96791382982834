<template>
  <div class="internal-container">
    <v-form ref="form" v-model="formValid">
      <v-layout row wrap>
        <v-flex class="px-2">
          <div>
            <label class="label" for="ado">Anti-Doping Organisation</label>
            <v-select solo flat elevation="0" id="ado" :items="formData.organizations" v-model="formData.ado"/>
          </div>
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs12 sm6>
          <div id="adams_id_box" v-if="!vadaChosen">
            <label class="label" for="adams_id">ADAMS ID</label>
            <v-text-field solo flat elevation="0" label="" name="adams_id" id="adams_id" v-model="formData.adams_id"/>
          </div>
          <div id="name_box" v-if="vadaChosen">
            <label class="label" for="name">NAME</label>
            <v-text-field solo flat elevation="0" label="" name="name" id="name" v-model="formData.name"/>
          </div>
        </v-flex>

        <v-flex xs12 sm6>
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="formData.date_of_test"
            persistent
            lazy
            full-width
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <div>
                <label class="label" for="date_of_test">Date of test</label>
                <v-text-field
                  v-model="formData.date_of_test"
                  flat
                  solo
                  elevation="0"
                  id="date_of_test"
                  name="date_of_test"
                  readonly
                  v-on="on"
                ></v-text-field>
              </div>
            </template>
            <v-date-picker v-model="formData.date_of_test" scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="$refs.dialog.save(formData.date_of_test)">OK</v-btn>
            </v-date-picker>
          </v-dialog>
        </v-flex>
      </v-layout>

      <input
        v-model="formData.last_name"
        name="last_name"
        tabindex="-1"
        style="background: transparent; position: fixed; top: -9999px; left: -9999px; color: transparent"
      />

      <v-layout row>
        <v-flex xs12 class="mt-2 pl-2 pr-2">
          <h4 class="small-headline">Method of notification</h4>
          <v-radio-group v-model="formData.method_of_notification" :mandatory="true">
            <v-layout row wrap>
              <v-flex xs6 xl3 class="mb-4">
                <v-radio @change="methodOfNotificationChange" label="Direct contact" value="direct_contact"/>
              </v-flex>
              <v-flex xs6 xl3 class="mb-4">
                <v-radio @change="methodOfNotificationChange" label="Phone call" value="phone_call"/>
              </v-flex>
              <v-flex xs6 xl3 class="mb-4">
                <v-radio @change="methodOfNotificationChange" label="Through another person" value="through_another_person"/>
              </v-flex>
              <v-flex xs6 xl3 class="mb-4">
                <v-radio @change="methodOfNotificationChange" label="Other" value="other"/>
              </v-flex>
<!--              <v-flex xs6 xl3 class="mb-4">-->
<!--                <v-radio @change="methodOfNotificationChange" label="None" value="none"/>-->
<!--              </v-flex>-->
            </v-layout>
          </v-radio-group>
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex class="pl-2 pr-2">
          <label class="label" for="detailed_notification_comments">Detail the notification (was athlete easy to find? Did you find a doorbell and/or name on the door? Did the athlete open the door right away etc.)</label>
          <v-textarea
            id="detailed_notification_comments"
            flat
            solo
            elevation="0"
            name="detailed_notification_comments"
            v-model="formData.detailed_notification_comments"
          />
        </v-flex>
      </v-layout>

      <v-layout row v-if="showHowBox">
        <v-flex class="mt-2 pl-2 pr-2">
          <label class="label" for="method_of_notification_other_text">Please add how</label>
          <v-text-field solo flat elevation="0" label="" name="method_of_notification_other_text" id="method_of_notification_other_text" v-model="formData.method_of_notification_other_text"/>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex class="mt-2 pl-2 pr-2">
          <label class="label" for="time_between_notification_and_sample">Time between notification and first collected sample</label>
          <v-text-field type="number" solo flat elevation="0" label="" suffix="min." name="time_between_notification_and_sample" id="time_between_notification_and_sample" v-model="formData.time_between_notification_and_sample"/>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex xs12 class="mt-2 pl-2 pr-2">
          <h4 class="small-headline mb-3">Analysis/UA</h4>
          <v-layout row wrap>
            <v-flex xs6 xl3>
              <v-checkbox v-model="formData.analysis" @change="resetValues" style="margin-top: 0;" label="BP" value="bp"/>
            </v-flex>
            <v-flex xs6 xl3>
              <v-checkbox v-model="formData.analysis" @change="resetValues" style="margin-top: 0;" label="Urine" value="urine"/>
            </v-flex>
            <v-flex xs6 xl3>
              <v-checkbox v-model="formData.analysis" @change="resetValues" style="margin-top: 0;" label="Serum" value="serum"/>
            </v-flex>
            <v-flex xs6 xl3>
              <v-checkbox v-model="formData.analysisUa" @change="resetValues" style="margin-top: 0;" label="UA" value="ua"/>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex xs12 sm6 class="mt-2 pl-2 pr-2">
          <h4 class="small-headline">Training status</h4>
          <v-radio-group v-model="formData.training_status" :mandatory="true">
            <v-layout row wrap>
              <v-flex xs4 class="mb-4">
                <v-radio @change="trainingStatusChange" label="Intense" value="intense"/>
              </v-flex>
              <v-flex xs4 class="mb-4">
                <v-radio @change="trainingStatusChange" label="Injured" value="injured"/>
              </v-flex>
              <v-flex xs4 class="mb-4">
                <v-radio @change="trainingStatusChange" label="Moderate" value="moderate"/>
              </v-flex>
              <v-flex xs4 class="mb-4">
                <v-radio @change="trainingStatusChange" label="Tapering" value="tapering"/>
              </v-flex>
              <v-flex xs4 class="mb-4">
                <v-radio @change="trainingStatusChange" label="Low" value="low"/>
              </v-flex>
              <v-flex xs4 class="mb-4">
                <v-radio @change="trainingStatusChange" label="Resting" value="resting"/>
              </v-flex>
            </v-layout>
          </v-radio-group>
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex class="pl-2">
          <v-layout align-center justify-space-between class="mb-4">
            <h4 class="small-headline">GPS</h4>
            <v-btn depressed class="ml-0" elevation="0" color="#04a1e3" dusk="gps-button" :disabled="coordinates_loading" :loading="coordinates_loading" @click="getCoordinates">
              <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20">
                <g fill="none" fill-rule="evenodd" stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" transform="translate(1 1)">
                  <path d="M14 7.364C14 13.09 7 18 7 18S0 13.09 0 7.364C0 3.297 3.134 0 7 0s7 3.297 7 7.364z"/>
                  <circle cx="7" cy="7" r="3"/>
                </g>
              </svg>
              Get Coordinates
            </v-btn>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout>
        <v-flex class="mt-2 pl-2 pr-2">
          <label class="label" for="name_of_dco">Name of DCO</label>
          <v-text-field solo flat elevation="0" label="" name="name_of_dco" id="name_of_dco" v-model="formData.name_of_dco"/>
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs12 sm6>
          <div>
            <label class="label" for="longitude">Longitude</label>
            <v-text-field solo flat elevation="0" name="longitude" id="longitude" v-model="formData.longitude"/>
          </div>
        </v-flex>
        <v-flex xs12 sm6>
          <div>
            <label class="label" for="latitude">Latitude</label>
            <v-text-field solo flat elevation="0" name="latitude" id="latitude" v-model="formData.latitude"/>
          </div>
        </v-flex>
      </v-layout>

      <h4 class="pl-2 small-headline mb-2">Coach</h4>
      <v-layout row wrap>
          <v-flex  xs12 sm4>
            <div>
              <label class="label" for="coach">Name</label>
              <v-text-field solo flat elevation="0" name="coach" id="coach" v-model="formData.coach"/>
            </div>
          </v-flex>

          <v-flex xs12 sm4>
            <div>
              <label class="label" for="coach_phone">Phone</label>
              <v-text-field solo flat elevation="0" name="coach_phone" id="coach_phone" v-model="formData.coach_phone"/>
            </div>
          </v-flex>

          <v-flex xs12 sm4>
            <div>
              <label class="label" for="coach_email">Email</label>
              <v-text-field solo flat elevation="0" name="coach_email" id="coach_email" v-model="formData.coach_email"/>
            </div>
          </v-flex>
      </v-layout>

      <h4 class="pl-2 small-headline mb-2">Manager</h4>
      <v-layout row wrap>
        <v-flex xs12 sm4>
          <div>
            <label class="label" for="manager">Name</label>
            <v-text-field solo flat elevation="0" name="manager" id="manager" v-model="formData.manager"/>
          </div>
        </v-flex>

        <v-flex xs12 sm4>
          <div>
            <label class="label" for="manager_phone">Phone</label>
            <v-text-field solo flat elevation="0" name="manager_phone" id="manager_phone" v-model="formData.manager_phone"/>
          </div>
        </v-flex>

        <v-flex xs12 sm4>
          <div>
            <label class="label" for="manager_email">Email</label>
            <v-text-field solo flat elevation="0" name="manager_email" id="manager_email" v-model="formData.manager_email"/>
          </div>
        </v-flex>
      </v-layout>

      <h4 class="pl-2 small-headline mb-2">Other</h4>
      <v-flex class="px-2">
          <label class="label" for="doctor">Doctor</label>
          <v-text-field solo flat elevation="0" name="doctor" id="doctor" v-model="formData.doctor"/>
      </v-flex>

      <v-layout row wrap>
        <v-flex xs12 sm6>
          <div>
            <label class="label" for="managed_by">Managed by/management</label>
            <v-text-field solo flat elevation="0" name="managed_by" id="managed_by" v-model="formData.managed_by"/>
          </div>
        </v-flex>

        <v-flex xs12 sm6>
          <div>
            <label class="label" for="future_races">Future races/Next Competition</label>
            <v-text-field solo flat elevation="0" name="future_races" id="future_races" v-model="formData.future_races"/>
          </div>
        </v-flex>
      </v-layout>

      <v-flex class="px-2">
        <label class="label" for="knowledge_of_whereabouts">Athletes knowledge of whereabouts</label>
        <v-text-field solo flat elevation="0" name="knowledge_of_whereabouts" id="knowledge_of_whereabouts" v-model="formData.knowledge_of_whereabouts"/>
      </v-flex>

      <v-layout row>
        <v-flex xs12 sm6 class="mt-2 pl-2 pr-2">
          <h4 class="small-headline">Quality of whereabouts</h4>
          <v-radio-group v-model="formData.quality_of_whereabouts" :mandatory="false">
              <v-radio label="Good" value="good"/>
              <v-radio label="Medium" value="Medium"/>
              <v-radio label="Low" value="low"/>
          </v-radio-group>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex xs12 sm6 class="mt-2 pl-2 pr-2">
          <h4 class="small-headline">Who enters your WA's?</h4>
          <v-layout>
            <v-radio-group v-model="formData.who_enters_your_was" :mandatory="false">
              <v-radio @change="WASChange" label="Yourself" value="yourself"/>
              <v-radio @change="WASChange" label="Another person" value="another_person"/>
            </v-radio-group>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout row wrap v-if="showWaBox">
        <v-flex xs12 sm6>
          <label class="label" for="was_full_name">Full name</label>
          <v-text-field solo flat elevation="0" name="was_full_name" id="was_full_name" v-model="formData.was_full_name"/>
        </v-flex>
        <v-flex xs12 sm6>
          <label class="label" for="was_relation">Relation</label>
          <v-text-field solo flat elevation="0" name="was_relation" id="was_relation" v-model="formData.was_relation"/>
        </v-flex>
        <v-flex xs12 sm6>
          <label class="label" for="was_phone">Phone</label>
          <v-text-field solo flat elevation="0" name="was_phone" id="was_phone" v-model="formData.was_phone"/>
        </v-flex>
        <v-flex xs12 sm6>
          <label class="label" for="was_email">Email</label>
          <v-text-field solo flat elevation="0" name="was_email" id="was_email" v-model="formData.was_email"/>
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex class="pl-2 pr-2">
          <label class="label" for="additional_comments">Short summary of the test (i.e. Glory Arena is an athletes' camp hotel. There's a security at the main entrance to the campus. First, I introduced myself and my chaperone with IDs and then I …)</label>
          <v-textarea
            id="additional_comments"
            flat
            solo
            elevation="0"
            name="additional_comments"
            v-model="formData.additional_comments"
          />
        </v-flex>
      </v-layout>

      <div v-for="(v, k) in errors" :key="k">
        <v-alert
          v-for="error in v" :key="error"
          :value="true"
          color="error"
          icon="warning"
          outline
        >
          {{ error }} <a :href="'#' + k">Go there</a>
        </v-alert>
      </div>

      <v-btn type="submit" depressed elevation="0" :loading="formLoading" class="ml-2 submit-button" color="#04a1e3" @click.prevent="submitForm">Submit</v-btn>
    </v-form>

    <v-snackbar
      :color="snackbar.color"
      v-model="snackbar.shown"
      :bottom="true"
      :right="true"
      :timeout="snackbar.timeout"
      :vertical="true"
    >
      {{ snackbar.text}}
    </v-snackbar>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        disabled: true,
        formValid: false,
        modal: false,
        coordinates_loading: false,
        formLoading: false,
        vadaChosen: false,
        showHowBox: false,
        showWaBox: false,
        errors: null,
        snackbar: {
          shown: false,
          timeout: 3000,
          text: '',
          color: '#019FE3'
        },
        formData: {
          gCaptchaResponse: '',
          organizations: [
            {
              text: 'AIU / World Athletics',
              value: 'IAAF'
            },
          ],
          ado: 'IAAF',
          name_of_dco: '',
          adams_id: '',
          date_of_test: new Date().toISOString().substr(0, 10),
          method_of_notification: 'direct_contact',
          detailed_notification_comments: '',
          method_of_notification_other: '',
          method_of_notification_other_text: '',
          time_between_notification_and_sample: '',
          training_status: 'moderate',
          quality_of_whereabouts: 'low',
          who_enters_your_was: 'yourself',
          was_full_name: '',
          was_relation: '',
          was_phone: '',
          was_email: '',
          last_name: '',
          name: '',
          coach_phone: '',
          coach_email: '',
          manager_phone: '',
          manager_email: '',
          analysis: ['bp'],
          analysisUa: '',
          knowledge_of_whereabouts: '',
          longitude: '',
          latitude: '',
          coach: '',
          doctor: '',
          manager: '',
          managed_by: '',
          additional_comments: ''
        },
      }
    },
    watch: {
      'formData.ado': function () {
        if (this.formData.ado === 'VADA') {
          this.vadaChosen = true;
        } else {
          this.vadaChosen = false;
        }
      },
      'formData.who_enters_your_was': function () {

      },
    },
    methods: {
      getCoordinates() {
        this.coordinates_loading = true;
        let vm = this;
        let options = {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0
        };

        function success(pos) {
          vm.coordinates_loading = false;
          vm.formData.longitude = pos.coords.longitude;
          vm.formData.latitude = pos.coords.latitude;

          vm.snackbar.shown = true;
          vm.snackbar.color = 'green';
          vm.snackbar.text = 'We found your coordinates!';
        }

        function error(err) {
          console.log(err)
          vm.coordinates_loading = false;

          vm.snackbar.color = 'red';
          vm.snackbar.shown = true;
          vm.snackbar.text = 'An error occurred while getting the coordinates, please try again.';
        }

        navigator.geolocation.getCurrentPosition(success, error, options);
      },
      submitForm() {
        if (this.$refs.form.validate()) {
          this.formLoading = true;

          // if(this.formData.method_of_notification[0] === 'other') {
          //   this.formData.method_of_notification = 'other';
          // }
          //
          // if(this.formData.who_enters_your_was[0] === 'yourself') {
          //   this.formData.who_enters_your_was = 'yourself';
          // }

          window.axios.post('/send-report', this.formData).then((response) => {
            this.formLoading = false;
            this.snackbar.color = 'green';
            this.snackbar.shown = true;
            this.snackbar.text = 'Your report was successfully submitted, thank you!';

            setTimeout(() => {
              window.location.reload();
              window.scrollTo(0, 0);
            }, 2000);
            //this.resetForm();
          }).catch((error) => {
            this.formLoading = false;
            if (!this.$refs.form.validate()) {
              this.snackbar.shown = true;
              this.snackbar.color = 'red';
              this.snackbar.text = 'Please fill in the required fields';
            } else {
              this.errors = error.response.data.errors;
              this.snackbar.shown = true;
              this.snackbar.color = 'red';
              this.snackbar.text = 'An error occurred while submitting the form, make sure you fill out all the fields and then try again.';
            }
          });
        }
      },
      resetValues(e) {
        if (e === 'ua') {
          this.formData.analysis = [];
        } else {
          this.formData.analysisUa = '';
        }
      },
      methodOfNotificationChange(e) {
        // if(e === 'none') {
        //   this.formData.method_of_notification = [];
        // }
        if (e === 'other') {
          this.showHowBox = true;
          this.formData.method_of_notification = [];
        } else {
          this.showHowBox = false;
        }
      },
      WASChange(e) {
        console.log(e);
        if (e === 'another_person') {
          this.showWaBox = true;
          this.formData.method_of_notification = [];
        } else {
          this.showWaBox = false;
        }
        // if (this.formData.who_enters_your_was.includes('another_person')) {
        //   this.showWaBox = true;
        //   this.who_enters_your_was = [];
        // } else {
        //   this.showWaBox = false;
        // }
      },
      trainingStatusChange(e) {
        console.log(e);
      },
      resetForm() {
        this.$refs.form.reset();
        this.formData.ado = 'IAAF';
        this.formData.adams_id = '';
        this.name = '';
        this.formData.analysis = 'bp';
        this.training_status = 'moderate';
        this.quality_of_whereabouts = 'low';
        this.who_enters_your_was = 'yourself';
        this.was_full_name = '';
        this.last_name = '';
        this.was_relation = '';
        this.was_phone = '';
        this.was_email = '';
        this.formData.method_of_notification = [];
        this.formData.method_of_notification_other = '';
        this.method_of_notification_other_text = '';
        this.detailed_notification_comments = '';
        this.time_between_notification_and_sample = '';
        this.formData.analysisUa = '';
        this.acknowledgement_of_whereabouts = '';
        this.coach_phone = '';
        this.coach_email = '';
        this.manager_phone = '';
        this.manager_email = '';
        this.formData.longitude = '';
        this.formData.latitude = '';
        this.formData.coach = '';
        this.formData.doctor = '';
        this.formData.manager = '';
        this.formData.managed_by = '';
        this.formData.additional_comments = '';

        setTimeout(() => {
          this.formData.date_of_test = new Date().toISOString().substr(0, 10);
        }, 1);
      },
    },
  }
</script>
