
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";

window.Vue = require('vue');
window.Vuetify = require('vuetify');

Vue.use(Vuetify, {
  theme: {
    primary: '#04a1e3'
  }
});

Sentry.init({
  dsn: "https://4264512944df4a51a4c0904d0f88b369@o184603.ingest.sentry.io/1430025",
  integrations: [new VueIntegration({ Vue, attachProps: true })],
});

Vue.component('report-form', require('./components/ReportForm.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
const app = new Vue({
  el: '#app'
});
